define("ods-app/controllers/requerimiento/record/aplicacion/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations", "ods-app/validations/changeset/candidato", "ods-app/models/candidato", "moment", "ods-app/utils/common"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations, _candidato, _candidato2, _moment, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    resolve
  } = Ember.RSVP;

  var _default = _abstractModuleRecord.default.extend({
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('requerimiento.record.aplicacion'),
    indexRoute: 'requerimiento.record.aplicacion',
    currentDate: null,
    reload: true,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    candidatoModel: Ember.A([]),
    currentDateTitle: Ember.computed('currentDate', function () {
      if (!this.get('currentDate')) {
        return;
      }

      let currentDate = (0, _moment.default)(this.get('currentDate'));
      return 'Debe ser anterior al ' + currentDate.format('DD/MM/YYYY');
    }),
    availableCandidates: Ember.computed(function () {
      return [];
    }),
    candidatoModelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'disponible'
        },
        value: 1
      }, {
        selectedFilter: {
          filterName: 'listaNegra'
        },
        value: 0
      }];
    }),
    candidatoModelClass: _candidato2.default,
    candidatoModelName: 'candidato',
    currentStep: 1,
    firstStep: 1,
    lastStep: 3,
    disablePrevious: Ember.computed.equal('currentStep', 1),
    disableNext: Ember.computed.equal('currentStep', 3),
    candidatoRecord: _candidato2.default,
    submitAvailable: false,
    candidatoCreateNew: null,
    candidatoSelected: false,
    candidatoChangeset: null,
    candidatoMultipleSelected: null,
    candidatoColumns: Ember.computed(function () {
      let columns = [{
        label: 'Fecha de creación',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'nombreCompleto',
        cellComponent: 'tables/cell/record-link',
        route: 'candidato.record',
        idRoute: 'id',
        linkTitle: 'Ir al candidato',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: 'nombreCompleto'
      }, {
        label: 'DPI',
        valuePath: 'uid',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'uid'
      }, {
        label: 'Ocupación',
        valuePath: 'ocupacion',
        sortable: false,
        filtrable: true,
        width: '200px',
        filterName: 'ocupacion'
      }, {
        label: 'Favorito',
        hidden: true,
        filtrable: true,
        filterName: 'esFavorito'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '80px',
        objectActions: {
          edit: {
            title: 'Asociar a requerimiento',
            buttonClass: 'btn-warning',
            icon: 'arrow-right',
            action: this.actions.asociarARequerimiento.bind(this)
          }
        }
      }];
      return Ember.A(columns);
    }),

    resetProperties() {
      this.setProperties({
        currentStep: 1,
        candidatoCreateNew: null,
        candidatoSelected: true,
        candidatoMultipleSelected: null,
        candidatoChangeset: null,
        submitAvailable: false
      });
    },

    createCandidatoChangeset() {
      const candidato = this.store.createRecord('candidato');

      if (this.modeloConfiguracionCandidato.get('length') > 1) {
        throw new Error(`Got more than one record for 'modelo-configuracion' with nombreModelo 'candidato'`);
      }

      if (this.modeloConfiguracionCandidato.get('length') === 1) {
        Ember.set(candidato, 'configuracion', this.modeloConfiguracionCandidato.get('firstObject'));
      }

      this.set('candidatoChangeset', new _emberChangeset.default(candidato, (0, _emberChangesetValidations.default)(_candidato.default), _candidato.default, {
        skipValidate: false
      }));
    },

    step1IsCompleted() {
      return resolve(this.candidatoCreateNew !== null);
    },

    step2IsCompleted() {
      if (!this.candidatoCreateNew) {
        if (!this.changeset.get('candidato.id')) {
          this.toast.error('Seleccione un candidato para continuar.');
          this.set('candidatoSelected', true);
          return resolve(false);
        }

        let candidato = this.changeset.get('candidato');
        this.changeset.set('expectativaSalarial', candidato.get('expectativaSalarial') || 0);
        this.changeset.set('fuenteReclutamiento', candidato.get('fuenteReclutamiento'));
        this.changeset.set('nombreReferido', candidato.get('nombreReferido'));
        this.set('candidatoSelected', true);
        this.set('submitAvailable', true);
        return resolve(true);
      }

      return this.candidatoChangeset.validate().then(() => {
        if (!this.candidatoChangeset.get('isValid')) {
          this.toast.error('Complete el formulario para continuar.');
          return false;
        }

        return this.candidatoChangeset.save().then(candidato => {
          this.changeset.set('candidato', candidato);
          this.changeset.set('expectativaSalarial', candidato.get('expectativaSalarial') || 0);
          this.changeset.set('fuenteReclutamiento', candidato.get('fuenteReclutamiento'));
          this.changeset.set('nombreReferido', candidato.get('nombreReferido'));
          this.set('submitAvailable', true);
          this.toast.success('Registro almacenado correctamente.');
          return true;
        }).catch(error => {
          (0, _common.handleChangesetSaveErrors)(this.candidatoChangeset, error, this.toast);
          return false;
        });
      });
    },

    canGoNextStep(currentStep) {
      const handler = `step${currentStep}IsCompleted`;

      if (Ember.typeOf(this[handler]) === 'function') {
        return this[handler]();
      }

      throw Error(`No function named ${handler} to handle step ${currentStep}.`);
    },

    postSave() {
      this.indexCtrl.reloadModel();
    },

    actions: {
      asociarARequerimiento(candidato) {
        let aplicaciones = this.requerimiento.get('aplicaciones');

        if (aplicaciones.findBy('candidato.id', candidato.get('id'))) {
          this.toast.error('Candidato ya asociado a este requerimiento.');
          return;
        }

        this.loader.setIsLoading();
        this.store.createRecord('aplicacion', {
          estatus: this.constants.APPLICATION_STATUS.get('firstObject.key'),
          candidato: candidato,
          requerimiento: this.requerimiento,
          expectativaSalarial: candidato.get('expectativaSalarial') || 0,
          fuenteReclutamiento: candidato.get('fuenteReclutamiento'),
          nombreReferido: candidato.get('nombreReferido')
        }).save().then(() => {
          this.toast.success('Candidato asociado correctamente.');
          this.indexCtrl.reloadModel();
        }).catch(() => {
          this.toast.error('Error al asociar candidato.');
        }).finally(() => this.loader.setNotLoading());
      },

      discardChanges() {
        if (this.changeset.get('isDirty')) {
          this.record.rollbackAttributes();
        }

        if (this.candidatoChangeset) {
          this.candidatoChangeset.rollback();

          this.candidatoChangeset._content.destroyRecord();
        }

        this.transitionToRoute(this.get('indexRoute'));
      },

      onCandidatoChanged() {
        this.set('candidatoSelected', this.changeset.get('candidato.id') ? true : false);

        if (!this.changeset.get('candidato.id')) {
          return;
        }

        let candidato = this.changeset.get('candidato');
        this.changeset.set('expectativaSalarial', candidato.get('expectativaSalarial') || 0);
        this.changeset.set('fuenteReclutamiento', candidato.get('fuenteReclutamiento'));
        this.changeset.set('nombreReferido', candidato.get('nombreReferido'));
      },

      goPreviousStep() {
        let regreso = 1;

        if (this.currentStep === this.firstStep) {
          return;
        }

        if (this.currentStep === 3) {
          regreso = 2;
        }

        this.set('currentStep', this.currentStep - regreso);
        this.set('submitAvailable', false);
      },

      goNextStep() {
        if (this.currentStep === this.lastStep) {
          return;
        }

        return this.canGoNextStep(this.currentStep).then(go => {
          if (go === false) {
            return;
          }

          this.set('currentStep', this.currentStep + 1);
        });
      },

      nextStepChoiceOption(option) {
        this.set('candidatoCreateNew', option === 1);
        this.set('candidatoSelected', option === 2);
        this.set('candidatoMultipleSelected', option === 3);

        if (this.candidatoCreateNew && !this.candidatoChangeset) {
          this.createCandidatoChangeset();
        }

        this.actions.goNextStep.bind(this)();
      },

      searchCandidates(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        } // Modificar filtro para que solo salgan los candidatos que no
        // tengan una aplicacion con el requerimiento


        this.contactsQuery = Ember.run.later(() => {
          this.set('availableCandidates', this.store.query('candidato', {
            filter: {
              primerNombre: {
                OR: text
              },
              primerApellido: {
                OR: text
              },
              segundoNombre: {
                OR: text
              },
              segundoApellido: {
                OR: text
              },
              uid: {
                OR: text
              },
              disponible: 1,
              listaNegra: 0
            }
          }));
        }, 500);
      },

      validateDPI(changeset, propertyName, value) {
        if (value.length < 13) {
          return;
        }

        this.store.query('candidato', {
          filter: {
            uid: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['DPI ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      },

      validatePasaporte(changeset, propertyName, value) {
        if (value.length < 10) {
          return;
        }

        this.store.query('candidato', {
          filter: {
            pasaporte: value
          }
        }).then(candidato => {
          changeset._deleteKey('_errors', propertyName);

          if (candidato.get('firstObject')) {
            changeset.addError(propertyName, ['Pasaporte ya registrado en el sistema']);
            return;
          }
        });
        changeset.set(propertyName, value);
      }

    }
  });

  _exports.default = _default;
});